<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.keyWord"
            size="medium"
            placeholder="姓名"
          ></el-input>
        </nav>
        <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >时间：</label
        >
        <nav class="input">
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="form_2.eventDate"
            style="width: 100%"
            size="medium"
          >
          </el-date-picker>
        </nav>

        <nav class="input" style="width: 123px;">
          <el-button @click="reset" size="small">重置</el-button>
          <el-button @click="subimt" type="primary" size="small">{{
            '搜索'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column type="selection"></el-table-column> -->
        <el-table-column prop="id" width="100" label="编号"> </el-table-column>
        <el-table-column prop="username" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="controller" label="模块"> </el-table-column>
        <el-table-column prop="action" label="操作"> </el-table-column>
        <el-table-column prop="ip" label="ip"> </el-table-column>
        <el-table-column
          prop="params"
          label="参数"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="createTime" label="操作时间" sortable="custom">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPage"
        :total="total"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      title="新增友情链接"
      :visible.sync="dialogVisiblexui"
      width="50%"
    >
      <el-form ref="form" :model="form_1" label-width="120px">
        <el-form-item label="友情链接名称">
          <el-input v-model="form_1.linkName"></el-input>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="form_1.linkUrl"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisiblexui = false
            form_1 = {}
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改友情链接" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" label-width="120px">
        <el-form-item label="友情链接名称">
          <el-input v-model="form.linkName"></el-input>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="form.linkUrl"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btn: true,
      currentPage: 1,
      pagesize: 10,
      list: [],
      form_2: {
        keyWord: '',
        eventDate: []
      },
      form: {
        id: '',
        linkName: '',
        linkUrl: ''
      },
      form_1: {
        linkName: '',
        linkUrl: ''
      },

      total: 0,
      dialogVisible: false,
      dialogVisiblexui: false
    }
  },
  methods: {
    rowClass() {
      return 'background:#F5F7FA;'
    },
    reset() {
      this.form_2 = {
        keyWord: '',
        eventDate: []
      }
      this.getlist()
    },
    getlist() {
      console.log(this.pagesize)
      console.log(this.currentPage)
      this.$http
        .get('/admin/Logs/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            order: this.order,
            ...this.form_2,
            eventDate: this.form_2.eventDate.join('~')
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res.data.list)
            this.list = res.data.list
            this.total = res.data.totalCount
            console.log(this.list)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    edit(e) {
      this.form.id = e
      this.dialogVisible = true
      this.$http
        .get('/admin/FriendLink/getById?id=' + e)
        .then(({ data: res }) => {
          this.form.linkName = res.data.linkName
          this.form.linkUrl = res.data.linkUrl
        })
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/FriendLink/edit', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.getlist()
              this.$message.success(res.message)
              this.dialogVisible = false
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    // this
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/FriendLink/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'activity_order_num'
        } else {
          s = 'rl_create_time'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    handleSizeChange(e) {
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },
    subimt() {
      this.currentPage = 1
      this.getlist()
    },
    addedit() {
      if (this.btn) {
        this.$http
          .post('/admin/FriendLink/add', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisiblexui = false
              location.reload()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    }
  },
  created() {
    this.getlist()
  }
}
</script>

<style lang="less" scoped>
.upload {
  width: 200px;
  height: 200px;
}
.skyblue {
  color: skyblue;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
